import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/usr/src/app/www/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`about me`}</h1>
    <h2>{`then`}</h2>
    <p>{`todo.`}</p>
    <h2>{`now`}</h2>
    <p>{`also todo. (apologies, updating this isn't on my priority list!)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      